<template>
  <div>
    <v-row justify="center" no-gutters>
      <h1 v-bind:class="mobile ? 'mainTitleMobile' : 'mainTitle'">{{ $t('mainTitle') }}</h1>
    </v-row>
    <v-row justify="center" v-bind:class="mobile ? 'firstSectionMobile' : 'firstSection'" no-gutters>
      <v-col cols="1" v-if="!mobile"></v-col>
      <v-col v-bind:cols="mobile ? 10 : 4" class="leftPart">
        <span class="firstSectionTextLeft" v-html="$t('firstSectionTextLeft1')"></span>
        <span class="firstSectionTextLeft textBlue" v-html="$t('firstSectionTextLeft2')"></span>
      </v-col>
      <v-col cols="1" v-if="!mobile"></v-col>
      <v-col v-bind:cols="mobile ? 10 : 4" class="rightPart">
        <br v-if="mobile">
        <p class="firstSectionTextRight" v-html="$t('firstSectionTextRight')"></p>
      </v-col>
      <v-col cols="1" v-if="!mobile"></v-col>
    </v-row>
    <v-row justify="center" v-bind:class="mobile ? 'sectionBlueMobile' : 'sectionBlue'" align="center" no-gutters>
      <v-col cols="6" v-if="!mobile">
        <v-img
            class="imgSectionLeft"
            :src="require('@/assets/conferenceFormation/conference1.png')"
            contain
            height="375"
        />
      </v-col>
      <v-col v-bind:cols="mobile ? 10 : 6">
        <p v-bind:class="mobile ? 'textMobile textBlue2' : 'textRight textBlue2'" v-html="$t('textSecondSection')"></p>
        <v-img
            v-if="mobile"
            :src="require('@/assets/conferenceFormation/conference1.png')"
            contain
            height="250"
        />
      </v-col>
    </v-row>
    <v-row justify="center" v-bind:class="mobile ? 'sectionWhiteMobile' : 'sectionWhite'" align="center" no-gutters>
      <v-col v-bind:cols="mobile ? 10 : 6">
        <p v-bind:class="mobile ? 'textMobile textBlue2' : 'textLeft textBlue2'" v-html="$t('textThirdSection1')"></p>
        <p v-bind:class="mobile ? 'textMobile textGrey' : 'textLeft textGrey'" v-html="$t('textThirdSection2')"></p>
        <p v-bind:class="mobile ? 'textMobile textBlue2' : 'textLeft textBlue2'" v-html="$t('textThirdSection3')"></p>
        <v-img
            v-if="mobile"
            :src="require('@/assets/conferenceFormation/conference2.png')"
            contain
            height="250"
        />
      </v-col>
      <v-col cols="6" v-if="!mobile">
        <v-img
            class="imgSectionRight"
            :src="require('@/assets/conferenceFormation/conference2.png')"
            contain
            height="375"
        />
      </v-col>
    </v-row>
    <v-row justify="center" v-bind:class="mobile ? 'sectionBlueMobile' : 'sectionBlue'" align="center" no-gutters>
      <v-col cols="6" v-if="!mobile">
        <v-img
            class="imgSectionLeft"
            :src="require('@/assets/conferenceFormation/conference3.png')"
            contain
            height="375"
        />
      </v-col>
      <v-col v-bind:cols="mobile ? 10 : 6">
        <p v-bind:class="mobile ? 'textMobile textBlue2' : 'textRight textBlue2'" v-html="$t('textFourthSection')"></p>
        <v-img
            v-if="mobile"
            :src="require('@/assets/conferenceFormation/conference3.png')"
            contain
            height="250"
        />
      </v-col>
    </v-row>
    <v-row justify="center" v-bind:class="mobile ? 'sectionWhiteMobile' : 'sectionWhite'" align="center" no-gutters>
      <v-col v-bind:cols="mobile ? 10 : 6">
        <p v-bind:class="mobile ? 'textMobile textBlue2' : 'textLeft textBlue2'" v-html="$t('textFifthSection1')"></p>
        <p v-bind:class="mobile ? 'textMobile textGrey' : 'textLeft textGrey'" v-html="$t('textFifthSection2')"></p>
        <p v-bind:class="mobile ? 'textMobile textBlue2' : 'textLeft textBlue2'" v-html="$t('textFifthSection3')"></p>
        <p v-bind:class="mobile ? 'textMobile textGrey' : 'textLeft textGrey'" v-html="$t('textFifthSection4')"></p>
        <v-img
            v-if="mobile"
            :src="require('@/assets/conferenceFormation/conference4.png')"
            contain
            height="250"
        />
      </v-col>
      <v-col cols="6" v-if="!mobile">
        <v-img
            class="imgSectionRight"
            :src="require('@/assets/conferenceFormation/conference4.png')"
            contain
            height="375"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
  export default {
    name: 'ConferenceSportHealthComp',
    props: ['mobile'],
    data: () => ({
    }),
  }
</script>

<i18n>
{
  "en": {
    "mainTitle": "Our sport health well-being conference",
    "firstSectionTextLeft1": "Raising the population's awareness of adopting good behavior with regard to their health by regularly practicing an adapted physical activity or by adopting a healthy lifestyle is one of our priorities. To do this, we have developed various conferences on the themes of sport, health and well-being. <br>",
    "firstSectionTextLeft2": "We have therefore developed various conferences on the themes of sport, health and well-being.",
    "firstSectionTextRight": "In the same vein, we also support professionals wishing to acquire new skills in order to be better able to support specific audiences.",
    "textSecondSection": "<strong> There are many good reasons to practice daily physical activity: </strong> <br> <br> Improve your well-being, be part of a group, enjoy nature, improve your endurance or relax. <br> <br> But how to practice in a way adapted to his age and his health? How often ? What intensity? What duration? What are the interests of one activity over another? <br> During this conference, a specialist in physical activities related to health will provide you with all these answers.",
    "textThirdSection1": "<br> <strong> Move more every day </strong>",
    "textThirdSection2": "Getting active on a daily basis is important, but the lack of time and knowledge on the subject sometimes hinders our desire to practice regularly. <br> <br> After briefly discussing the recommendations for physical activities, a health-related physical activity specialist will provide you with practical tools to use on a daily basis to maintain a sufficient level of physical activity. <br> <br>",
    "textThirdSection3": "<strong> At the end of the session, you will go from theory to practice by doing a small easy and effective session. </strong>",
    "textFourthSection": "<br> <strong> Retirement and confinement: what program to adapt my diet and preserve my muscle mass? </strong> <br> <br> Containment has changed our habits: more time at home, less activity. <br> Let's use our time at home to take care of ourselves. <br> <br> <strong> We offer you a fitness plan that you will tell us about! </strong> <br> Food program and sports routine, for 1h30 we offer you the keys to maintain your muscle mass. <br> You will of course leave with the program and a home exercise book that will help you on a daily basis!",
    "textFifthSection1": "<strong> Keep your balance at any age! </strong>",
    "textFifthSection2": "Fall is one of the main causes of loss of autonomy for seniors. <br> To maintain a good balance over the years, you first have to understand how it works. <br> <br>",
    "textFifthSection3": "<strong> Vision, inner ear, proprioception, cognition? </strong>",
    "textFifthSection4": "A specialist in balance and adapted physical activities will explain everything to you and give you practical advice on a daily basis to maintain your balance."
  },
  "fr": {
    "mainTitle": "Nos conférences sport santé bien être",
    "firstSectionTextLeft1": "Sensibiliser la population à adopter des bons comportements au regards de leur santé en pratiquant régulièrement une activité physique adaptée ou en adoptant une bonne hygiène de vie est une de nos priorité. <br>",
    "firstSectionTextLeft2": " Nous avons pour cela développé différentes conférences sur les thèmes du sport santé bien être.",
    "firstSectionTextRight": "Dans la même veine, nous accompagnons également des professionnels souhaitant acquérir de nouvelles compétences afin d’être plus à même d’accompagner des publics spécifiques.",
    "textSecondSection": "<strong>Les bonnes raisons de pratiquer une activité physique au quotidien sont nombreuses : </strong> <br><br> Améliorer son bien-être, faire partie d’un groupe, profiter de la nature, améliorer son endurance ou encore se détendre. <br><br> Mais comment pratiquer de façon adaptée à son âge et à sa santé ? A quelle fréquence ? Quelle intensité ? Quelle durée ? Quels sont les intérêts d’une activité par rapport à une autre ? <br> Au cours de cette conférence, un spécialiste des activités physiques en lien avec la santé vous apportera toutes ces réponses.",
    "textThirdSection1": "<br> <strong> Bougez plus au quotidien </strong>",
    "textThirdSection2": "Bougez au quotidien est important, mais le manque de temps et de connaissances sur le sujet nous freine parfois dans notre envie de pratiquer régulièrement. <br><br> Après avoir parlé brièvement des recommandations en activités physiques, un spécialiste des activités physiques en lien avec la santé, vous apportera des outils pratiques à utiliser au quotidien pour maintenir un niveau d’activité physique suffisant. <br><br>",
    "textThirdSection3": "<strong>En fin de séance, vous passerez de la théorie à la pratique en réalisant une petite séance facile et efficace. </strong>",
    "textFourthSection": " <br><strong>Retraité et confinement : quel programme pour adapter mon alimentation et préserver ma masse musculaire ? </strong> <br><br> Le confinement a changé nos habitudes : plus de temps à la maison, moins d’activité. <br> Mettons à profit notre temps à la maison pour prendre soin de nous. <br><br> <strong> Nous vous proposons un plan de remise en forme dont vous nous direz des nouvelles ! </strong> <br> Programme alimentaire et routine sportive, pendant 1h30 on vous offre les clés pour entretenir votre masse musculaire. <br> Vous repartirez bien sûr avec le programme et un carnet d’exercices à domicile qui vous aidera au quotidien.",
    "textFifthSection1": "<strong>Garder l’équilibre à tout âge ! </strong>",
    "textFifthSection2": "La chute est une des causes principales de perte d’autonomie pour les seniors. <br> Pour entretenir un bon équilibre au fil des années, il faut avant tout comprendre comment il fonctionne.  <br><br>",
    "textFifthSection3": "<strong>Vision, oreille interne, proprioception, cognition ? </strong>",
    "textFifthSection4": "Un spécialiste de l’équilibre et des activités physiques adaptées vous expliquera tout et vous donnera des conseils pratiques au quotidien pour entretenir votre équilibre."
  }
}
</i18n>

<style scoped>

.firstSection {
  padding-top: 20px;
  padding-bottom: 50px;
  color: #808080;
}

.firstSectionMobile {
  font-size: 14px;
  padding-bottom: 40px;
  color: #808080;
}

.leftPart {
  padding-left: 200px;
  padding-right: 50px;
}

.rightPart {
  padding-left: 50px;
  padding-right: 200px;
}

.textMobile {
  font-size: 14px;
}

.textBlue {
  color: #1579A7;
}

.textBlue2 {
  color: #57939E;
}

.textGrey {
  color: #808080;
}

.sectionBlue {
  padding: 50px;
  background-color: #D5EEF4;
}

.sectionBlueMobile {
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #D5EEF4;
}

.sectionWhite {
  padding: 50px;
}

.sectionWhiteMobile {
  padding-top: 30px;
  padding-bottom: 40px;
}

.textRight {
  padding-right: 200px;
}

.textLeft {
  padding-right: 50px;
  padding-left: 200px;
}

.imgSectionLeft {
  margin-left: 75px;
}

.imgSectionRight {
  margin-right: 100px;
}

</style>
