<template>
  <div>
    <ConferenceSportHealthComp v-bind:mobile="mobile"/>
  </div>
</template>

<script>
import ConferenceSportHealthComp from '@/components/desktop/ConferenceFormation/ConferenceSportHealthComp'

  export default {
    name: 'ConferenceSportHealth',
    props: ['mobile'],
    components: {
      ConferenceSportHealthComp,
    },
  }
</script>
